import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import './styles.scss'

const AccordionItem = loadable(() => import('../AccordionItem'))

const Accordion = ({ data }) => {
    return (
        data && (
            <div className="c-accordion">
                {data.map((node, index) => (
                    <AccordionItem key={index} data={node} />
                ))}
            </div>
        )
    )
}

Accordion.propTypes = {
    /**
     * Data
     */
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.string
        })
    )
}

Accordion.defaultProps = {
    data: [
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        },
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        },
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        },
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        },
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        }
    ]
}

export default Accordion
